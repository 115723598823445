import React from 'react';
import { hooks } from 'mage-i18n';
import { Typography, SpacerVertical } from '@klarna/bubble-ui';
const Paragraph = ({ text, index }) => (React.createElement(React.Fragment, null,
    index > 0 && React.createElement(SpacerVertical, { spaceToken: 'space/100' }),
    React.createElement(Typography, { textToken: 'text-style/text/super-paragraphs/body/regular' }, text)));
const DefaultBotMessage = ({ translationKey }) => {
    const t = hooks.useTranslator();
    const text = t(translationKey);
    const paragraphs = text
        .split('\n')
        .map((text, i) => React.createElement(Paragraph, { text: text, key: i, index: i }));
    return React.createElement(React.Fragment, null, paragraphs);
};
export default DefaultBotMessage;
