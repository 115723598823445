import { ActionIconArrowLeft, ButtonRoundSecondary, Link, SpacerHorizontal } from '@klarna/bubble-ui';
import { Flex } from '@klarna/mp-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
const LinkStyled = styled.span `
  cursor: pointer;
`;
function BackButton({ path, onClick, label }) {
    const history = useHistory();
    const onBackButtonClick = () => {
        onClick === null || onClick === void 0 ? void 0 : onClick();
        if (path) {
            history === null || history === void 0 ? void 0 : history.push(path);
        }
        else {
            history === null || history === void 0 ? void 0 : history.goBack();
        }
    };
    return (React.createElement(Flex, { alignItems: "center" },
        React.createElement(ButtonRoundSecondary, { id: "mpui-fw-header-backbutton", "aria-label": label, size: "small", onPress: onBackButtonClick },
            React.createElement(ActionIconArrowLeft, null)),
        label && (React.createElement(React.Fragment, null,
            React.createElement(SpacerHorizontal, { spaceToken: "space/200" }),
            React.createElement(LinkStyled, null,
                React.createElement(Link, { textToken: "text-style/text/paragraphs/default/bold", colorToken: "colors/text/neutral", onPress: onBackButtonClick }, label))))));
}
BackButton.propTypes = {
    label: PropTypes.string.isRequired,
    path: PropTypes.string,
    onClick: PropTypes.func
};
export default BackButton;
