import { ActionIconArrowLeft, ActionIconArrowRight, ButtonRoundQuaternary, Typography } from '@klarna/bubble-ui';
import { Flex } from '@klarna/mp-ui';
import { hooks as i18nHooks, selectors as i18nSelectors } from 'mage-i18n';
import Insights from 'mage-insights';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { insightsDateUtils as utils } from '../model';
export const WeekDayNavigator = ({ date, isWide, onChange }) => {
    const dateFormatter = useSelector(i18nSelectors.formatters.longDateNoYear);
    const t = i18nHooks.useTranslator();
    const getWeekDay = () => {
        if (utils.isTodayOrAfter(date)) {
            const text = t('home-fe.insightsWidget.relativeDate.today');
            if (typeof text === 'string') {
                return text.replace('[', '').replace(']', '');
            }
            else {
                return text;
            }
        }
        if (utils.isYesterday(date)) {
            const text = t('home-fe.insightsWidget.relativeDate.yesterday');
            if (typeof text === 'string') {
                return text.replace('[', '').replace(']', '');
            }
            else {
                return text;
            }
        }
        return t(`home-fe.insightsWidget.weekDays.${utils.getDayOfWeek(date)}`);
    };
    const handlePrevClick = () => {
        if (!utils.isOneWeekAgoOrBefore(date)) {
            onChange(Date.parse(utils.getPrevDay(date)));
        }
        Insights.event({
            category: 'homepageContent:salesWidget',
            action: 'change_date',
            label: 'previous_day'
        });
    };
    const handleNextClick = () => {
        if (!utils.isTodayOrAfter(date)) {
            onChange(Date.parse(utils.getNextDay(date)));
        }
        Insights.event({
            category: 'homepageContent:salesWidget',
            action: 'change_date',
            label: 'next_day'
        });
    };
    return (
    // @ts-ignore: growChildren prop not valid
    React.createElement(Flex, { column: isWide, spacing: 'space/100', justifyContent: 'space-between', growChildren: [1] },
        React.createElement(Flex, { spacing: 'space/300' },
            React.createElement(Flex, { column: true, spacing: 'space/100' },
                React.createElement(Typography, { textToken: 'text-style/headings/blocks/bold/grande' }, dateFormatter.format(date)),
                React.createElement(Typography, { textToken: 'text-style/text/labels/default/regular', colorToken: 'colors/text/neutral' }, getWeekDay())),
            React.createElement(Flex, { spacing: 'space/100' },
                React.createElement(ButtonRoundQuaternary, { size: 'small', disabled: utils.isOneWeekAgoOrBefore(date), onClick: handlePrevClick },
                    React.createElement(ActionIconArrowLeft, null)),
                React.createElement(ButtonRoundQuaternary, { size: 'small', disabled: utils.isTodayOrAfter(date), onClick: handleNextClick },
                    React.createElement(ActionIconArrowRight, null))))));
};
WeekDayNavigator.propTypes = {
    date: PropTypes.number.isRequired,
    isWide: PropTypes.bool,
    onChange: PropTypes.func.isRequired
};
