import PropTypes from 'prop-types';
import React from 'react';
function HeaderMenuButton({ id, onClick, children }) {
    return (React.createElement("div", { id: id, style: { display: 'flex', alignItems: 'center' }, onClick: onClick }, children));
}
HeaderMenuButton.propTypes = {
    id: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};
export default HeaderMenuButton;
