import useAuthStatus from "../../hooks/useAuthStatus";
import useStepUpAuthenticationModal from "../../hooks/useStepUpAuthenticationModal";
const useMfaLockedButton = (params) => {
    const { hasUserAuthLevel } = useAuthStatus();
    const userIsMissingAuth = !hasUserAuthLevel('krn:portal-auth:loa:2fa');
    const { Modal: MfaModal, openModal } = useStepUpAuthenticationModal();
    const handleClick = () => {
        if (userIsMissingAuth) {
            (params === null || params === void 0 ? void 0 : params.onLockedClick) && params.onLockedClick();
            openModal();
            return;
        }
        (params === null || params === void 0 ? void 0 : params.onUnlockedClick) && params.onUnlockedClick();
    };
    return {
        handleClick,
        userIsMissingAuth,
        MfaModal
    };
};
export default useMfaLockedButton;
