import { ActionIconClose, ButtonRoundQuaternary } from '@klarna/bubble-ui';
import { Portal, useGetToken } from '@klarna/mp-ui';
import React from 'react';
import { CloseButtonContainer, PopoverContainer } from './PopoverStyled';
export const Popover = ({ componentRef, onClose, children, padding, position }) => {
    var _a;
    const refCoordinates = ((_a = componentRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()) || { top: 0, left: 0 };
    const backgroundColor = useGetToken('colors/backgrounds/default').toString();
    return (React.createElement(Portal, null,
        React.createElement(PopoverContainer, { backgroundColor: backgroundColor, refCoordinates: refCoordinates, "data-testid": "popover-container", padding: padding, position: position },
            children,
            React.createElement(CloseButtonContainer, null,
                React.createElement(ButtonRoundQuaternary, { "data-testid": "popover-close-button", onClick: onClose, size: "small" },
                    React.createElement(ActionIconClose, null))))));
};
