import { ColoredIllustrationEmptyState, IllustrationErrorExplodingBalloon, SpacerVertical, Typography } from '@klarna/bubble-ui';
import { useGetToken } from '@klarna/mp-ui';
import React from 'react';
import styled from 'styled-components';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1tfjbnc" })(() => {
    const paddingHorizontal = useGetToken('space/300').value();
    return {
        padding: `0 ${paddingHorizontal}px`
    };
});
const EmptyOrErrorComponent = (props) => {
    const { title, desc, isError } = props;
    const dataTestId = (isError)
        ? 'illustration-error-exploding-ballon'
        : 'illustration-empty-state';
    const Illustration = (isError)
        ? IllustrationErrorExplodingBalloon
        : ColoredIllustrationEmptyState;
    return (React.createElement(Container, null,
        React.createElement(Illustration, { "data-testid": dataTestId }),
        React.createElement("br", null),
        React.createElement(Typography, { textToken: 'text-style/text/paragraphs/tall/regular' }, title),
        React.createElement(SpacerVertical, { spaceToken: 'space/100' }),
        React.createElement(Typography, { textToken: 'text-style/text/paragraphs/body/regular' }, desc)));
};
export default EmptyOrErrorComponent;
