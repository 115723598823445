import { Badge } from '@klarna/bubble-ui';
import React from 'react';
import styled from 'styled-components';
const BadgeContainer = styled.div `
  position: absolute;
  z-index: 1;
  top: -8px;
  right: -8px;

  animation: ${({ animated }) => (animated ? 'pulse 1.2s ease-in-out infinite' : 'none')};
  height: 20px;
  border-radius: 10px;

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0px rgba(255, 179, 199, 0.3);
      border-radius: 10px;
    }
    100% {
      box-shadow: 0 0 0 13px rgba(255, 179, 199, 0.3);
      border-radius: 32px;
    }
  }
`;
export default function NotificationBadge({ animated = false, children }) {
    return (React.createElement(BadgeContainer, { animated: animated },
        React.createElement(Badge, { backgroundColorToken: "colors/borders/error" }, children)));
}
