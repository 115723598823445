import { IconNotification } from '@klarna/bubble-ui';
import { TopBarActionButton } from '@merchant-portal/framework';
import React from 'react';
export const NotificationButton = ({ onClick, isToggled }) => {
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };
    return (React.createElement(TopBarActionButton, { iconOnly: true, toggled: isToggled, "data-testid": "notification-header-button", label: "", Icon: IconNotification, onClick: handleClick }));
};
