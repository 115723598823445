import { ActionIconArrowRight, Link, SpacerVertical, Typography } from '@klarna/bubble-ui';
import Insights, { TrackImpression } from 'mage-insights';
import React from 'react';
import styled from 'styled-components';
import CardWrapper from '../../../../../components/AppCard/CardWrapper';
const TopAlignment = styled.div.withConfig({ displayName: "TopAlignment", componentId: "sc-txfoc1" }) `
  height: 100%;
`;
const Image = styled.div.withConfig({ displayName: "Image", componentId: "sc-1qm5l16" }) `
  background-image: url('${({ imageUrl }) => imageUrl}');
  background-color: #E6FFA9;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: bottom center;
  border-radius: var(--corner-radius-m, 16px);
  border: 1px solid #E3E5E9;
  max-height: 160px;
  min-height: 160px;
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    display: block;
    padding-top: 45.28%;
  }
`;
const CallToActionWrapper = styled.div.withConfig({ displayName: "CallToActionWrapper", componentId: "sc-17satpw" }) `
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
function AppCard({ imageUrl, description, clientId, url, buttonLabel, componentRef }) {
    const category = `home-fe/v3/boost-home/install-websdk/${clientId}`;
    const impressionEvent = {
        category,
        action: 'impression'
    };
    const onClick = () => {
        Insights.event({
            category,
            action: 'click'
        });
        location.href = url;
    };
    return (React.createElement("div", { ref: componentRef },
        React.createElement(TrackImpression, { event: impressionEvent },
            React.createElement(CardWrapper, { onClick: onClick },
                React.createElement(TopAlignment, null,
                    React.createElement(Image, { imageUrl: imageUrl }),
                    React.createElement(SpacerVertical, { spaceToken: 'space/200' }),
                    React.createElement(Typography, { textToken: 'text-style/text/paragraphs/default/regular', textAlignToken: 'text-alignment/start', accessibilityPreset: 'paragraph' }, description),
                    React.createElement(SpacerVertical, { spaceToken: 'space/100' }),
                    React.createElement(CallToActionWrapper, null,
                        React.createElement(Link, { onPress: onClick, isUnderlined: true }, buttonLabel),
                        React.createElement(ActionIconArrowRight, null)))))));
}
export default AppCard;
