import { __awaiter } from "tslib";
import { ButtonPrimary, SpacerHorizontal, SpacerVertical, Typography } from '@klarna/bubble-ui';
import { useGetToken, useResponsiveContext } from '@klarna/mp-ui';
import { usePageContext } from '@merchant-portal/framework';
import { hooks as i18nHooks } from 'mage-i18n';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import useAuthStatus from '../../hooks/useAuthStatus';
import SetUpRequired from './SetUpRequired';
const ContentContainer = styled.div `
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${({ isMobile, isModal }) => (!isModal && !isMobile) && `
    width: 45%;
  `}
`;
const ButtonContainer = styled.div `
  display: flex;
  justify-content: space-between;
`;
const ActionButton = styled(ButtonPrimary) `
  ${({ isModal }) => isModal && `
    width: 100%;
  `}
`;
// TODO: replace this button with the new bubble-ui button once we upgrade to bubble-ui v6
const SkipButton = styled.button `
  background: none;
  border: none;
  border-radius: 9999px;
  font-size: 16px;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s;
  &:hover {
    background-color: ${({ hoverColor }) => hoverColor};
  }
  ${({ isModal }) => isModal && `
    width: 100%;
  `}
`;
const Content = ({ isModal = false, onClose = () => { } }) => {
    var _a;
    const { setPageContext = () => { } } = usePageContext();
    const { completeAuthentication, setUpAuthFactors } = useAuthStatus();
    const t = i18nHooks.useTranslator();
    const isSetUpRequired = ((_a = setUpAuthFactors === null || setUpAuthFactors === void 0 ? void 0 : setUpAuthFactors.length) !== null && _a !== void 0 ? _a : 0) < 2;
    const hoverColor = useGetToken('colors/backgrounds/subtle-inline').toString();
    const { isMobile } = useResponsiveContext();
    const completeAuthenticationHandler = () => __awaiter(void 0, void 0, void 0, function* () { return yield completeAuthentication(); });
    useEffect(() => {
        !isModal && setPageContext({
            title: t(`core.stepUpAuthentication.${isSetUpRequired ? 'setUp' : 'login'}.title`)
        });
    }, [isModal]);
    return (React.createElement(ContentContainer, { isMobile: isMobile, isModal: isModal },
        isModal &&
            React.createElement(React.Fragment, null,
                React.createElement(Typography, { textToken: 'text-style/headings/titles/bold/primary' }, t(`core.stepUpAuthentication.${isSetUpRequired ? 'setUp' : 'login'}.title`)),
                React.createElement(SpacerVertical, { spaceToken: "space/300" })),
        isSetUpRequired &&
            React.createElement(React.Fragment, null,
                React.createElement(SetUpRequired, null),
                React.createElement(SpacerVertical, { spaceToken: "space/300" })),
        React.createElement(ButtonContainer, null,
            isModal &&
                React.createElement(React.Fragment, null,
                    React.createElement(SkipButton, { isModal: isModal, hoverColor: hoverColor, onClick: onClose },
                        React.createElement(Typography, { textToken: 'text-style/text/labels/body/medium' }, t('core.stepUpAuthentication.skipButton'))),
                    React.createElement(SpacerHorizontal, { spaceToken: "space/100" })),
            React.createElement(ActionButton, { isModal: isModal, onClick: completeAuthenticationHandler, size: 'medium' }, t(`core.stepUpAuthentication.${isSetUpRequired ? 'setUp' : 'login'}.continueButton`)))));
};
export default Content;
