import { useResponsiveContext } from '@klarna/mp-ui';
import React, { useCallback, useEffect, useReducer } from 'react';
import { NotificationsContainer, NotificationsContainerMobile } from './styled';
import Toast from './Toast';
const reducer = (state, action) => {
    switch (action.type) {
        case 'ADD':
            return [...state, action.props];
        case 'REMOVE':
            return state.filter(t => t.toastId !== action.toastId);
        default:
            return state;
    }
};
/**
 * A container for notifications
 */
const Notifications = ({ setNotificationFunctions }) => {
    const [state, dispatch] = useReducer(reducer, []);
    const { isMobile } = useResponsiveContext();
    const removeToastMessage = useCallback(toastId => {
        dispatch({ type: 'REMOVE', toastId });
    }, [dispatch]);
    const showToastMessage = useCallback((toastProps) => {
        const toastId = Date.now().toString() + toastProps.message;
        const props = Object.assign(Object.assign({}, toastProps), { toastId });
        dispatch({ type: 'ADD', props });
    }, [dispatch]);
    useEffect(() => {
        setNotificationFunctions({
            showToastMessage
        });
    }, [setNotificationFunctions, showToastMessage]);
    const ContainerDiv = isMobile ? NotificationsContainerMobile : NotificationsContainer;
    return (React.createElement(ContainerDiv, { id: "mpui-notifications-container" }, state.map(props => (React.createElement(Toast, Object.assign({ key: props.toastId, onRemoved: removeToastMessage }, props))))));
};
export default Notifications;
