import { ColoredIllustrationRocket, SpacerVertical, Typography } from '@klarna/bubble-ui';
import { useResponsiveContext } from '@klarna/mp-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import React from 'react';
import PopOverWithHighlight from './PopOverWithHighlight';
import useFeatureHighlight from './useFeatureHighlight';
const FEATURE_BOOSTERS_HIGHLIGHT = 'mp:featuresShown:tabs:boosters';
const DELAY_TO_SHOW_MS = 1000;
const ANALYTICS_NAME = 'highlight/tabs/boosters';
export default function PopoverBoostersInfo({ boostersTabRef }) {
    const { isMobile } = useResponsiveContext();
    const t = i18nHooks.useTranslator();
    const [mustShowPopOver, closePopOver] = useFeatureHighlight(FEATURE_BOOSTERS_HIGHLIGHT, DELAY_TO_SHOW_MS, ANALYTICS_NAME);
    return (React.createElement(PopOverWithHighlight, { showPopOver: mustShowPopOver, popOverOnClose: closePopOver, componentRef: boostersTabRef, position: isMobile ? 'bottom' : 'right' },
        React.createElement(ColoredIllustrationRocket, { size: 50 }),
        React.createElement(SpacerVertical, { spaceToken: "space/200" }),
        React.createElement(Typography, { textToken: "text-style/text/paragraphs/body/bold" }, t('framework.popOvers.boostersTab.title')),
        React.createElement(Typography, { textToken: "text-style/text/paragraphs/default/regular" }, t('framework.popOvers.boostersTab.content')),
        React.createElement(SpacerVertical, { spaceToken: "space/200" })));
}
