import { Loader } from '@klarna/bubble-ui';
import { Layout, useGetToken, useResponsiveTokenValue } from '@klarna/mp-ui';
import { selectors as coreSelectors } from 'mage-core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useTablet } from '../../hooks/useMediaQuery';
import { APP_CONTENT_MAX_WIDTH, zIndex } from './constants';
import { PageContext } from './Contexts/PageContext';
import { Error as ErrorComponent } from './Error';
import PageHeader from './PageHeader';
const AppStyled = styled.div(({ hideFooter, hideSidebar, hideTopBar, isFullScreenContent }) => {
    const tabletMediaQuery = useTablet();
    const footerHeight = useGetToken('space/800').value();
    const backgroundColor = useGetToken('colors/backgrounds/default').toString();
    const paddingTopWithTopBarHidden = useGetToken('space/600').value();
    const paddingTop = useGetToken('space/200').value();
    const horizonalOffset = useResponsiveTokenValue('framework/main/margin');
    const cornerRadiusM = useGetToken('corner-radius/m').value();
    const borderRadiusTopLeft = hideSidebar || hideTopBar ? 0 : cornerRadiusM;
    const borderRadiusBottomLeft = hideSidebar || hideFooter ? 0 : cornerRadiusM;
    const footerHeightOffset = hideFooter ? 0 : footerHeight;
    const defaultPaddingTop = hideTopBar ? paddingTopWithTopBarHidden : paddingTop;
    const paddingHorizontal = isFullScreenContent ? 0 : horizonalOffset;
    return css({
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor,
        minHeight: `calc(100% - ${footerHeightOffset}px)`,
        paddingLeft: paddingHorizontal,
        paddingRight: paddingHorizontal,
        paddingTop: isFullScreenContent ? 0 : defaultPaddingTop,
        zIndex: zIndex.appContent.root,
        [tabletMediaQuery]: {
            paddingTop: isFullScreenContent ? 0 : paddingTop,
            borderRadius: `${borderRadiusTopLeft}px 0 0 ${borderRadiusBottomLeft}px`
        }
    });
});
const MainStyled = styled.div(({ isFullScreenContent }) => {
    const paddingVertical = useGetToken('space/300').value();
    const isKlarnaRealm = useSelector(coreSelectors.isKlarnaRealm);
    return css({
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: isFullScreenContent ? 0 : paddingVertical,
        paddingBottom: isFullScreenContent || isKlarnaRealm ? 0 : paddingVertical,
        position: 'relative',
        zIndex: zIndex.appContent.main
    });
});
const InnerContainerAppStyled = styled.div(({ isFullScreenContent }) => {
    return {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: '100%',
        margin: '0 auto',
        maxWidth: isFullScreenContent ? undefined : APP_CONTENT_MAX_WIDTH,
        width: '100%'
    };
});
function AppContent({ children, frameworkError, isFrameworkLoading, isFullScreenContent, stateContext }) {
    const [pageContext, setPageContext] = useState({
        title: ''
    });
    const [pageHeaderHeight, setPageHeaderHeight] = useState(null);
    const showFrameworkError = !!frameworkError;
    const showFrameworkLoading = isFrameworkLoading && !showFrameworkError;
    const showAppContent = !showFrameworkError && !isFrameworkLoading;
    const showPageError = !!pageContext.error;
    const showPageLoading = pageContext.isLoading && !showPageError;
    const showPageContent = !showPageLoading && !showPageError;
    return (React.createElement(PageContext.Provider, { value: { setPageContext } },
        React.createElement(AppStyled, Object.assign({}, stateContext, { pageHeaderHeight: pageHeaderHeight, isFullScreenContent: isFullScreenContent }),
            React.createElement(InnerContainerAppStyled, { isFullScreenContent: isFullScreenContent },
                showFrameworkError && React.createElement(Error, Object.assign({}, frameworkError)),
                showFrameworkLoading && React.createElement(Loading, null),
                showAppContent && (React.createElement(React.Fragment, null,
                    React.createElement(PageHeader, Object.assign({}, pageContext, { setHeight: setPageHeaderHeight })),
                    showPageError && React.createElement(Error, Object.assign({}, pageContext.error)),
                    showPageLoading && React.createElement(Loading, null),
                    showPageContent && (React.createElement(MainStyled, { id: "mpui-fw-main-section", "data-testid": "mpui-fw-main-content", isFullScreenContent: isFullScreenContent }, children))))))));
}
const LoaderStyled = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    flexGrow: 1
});
function Loading() {
    return (React.createElement(LoaderStyled, null,
        React.createElement(Loader, null)));
}
function Error(errorProps) {
    return (React.createElement(Layout.Grid, null,
        React.createElement(Layout.Section, null,
            React.createElement(Layout.Column, { desktopWidth: 8, tabletWidth: 12, mobileWidth: 12 },
                React.createElement(ErrorComponent, Object.assign({}, errorProps))))));
}
export default AppContent;
