import { SpacerVertical, Typography } from '@klarna/bubble-ui';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
const FlexWithEllipsis = styled.div(({ maxWidth }) => {
    return css({
        display: 'flex',
        flexDirection: 'column',
        maxWidth,
        span: {
            width: '100%',
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    });
});
const TextValueWithEllipsis = ({ content, overflowEllipsisWidth, title, subtitle }) => (React.createElement(FlexWithEllipsis, { maxWidth: overflowEllipsisWidth },
    React.createElement(Typography, { textToken: "text-style/text/labels/default/regular", colorToken: "colors/text/neutral" }, title),
    React.createElement(SpacerVertical, { spaceToken: "space/50" }),
    React.createElement(Typography, { textToken: "text-style/text/labels/body/medium" }, content),
    React.createElement(SpacerVertical, { spaceToken: "space/50" }),
    React.createElement(Typography, { textToken: "text-style/text/labels/default/regular", colorToken: "colors/text/neutral" }, subtitle)));
TextValueWithEllipsis.propTypes = {
    content: PropTypes.string,
    overflowEllipsisWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    subtitle: PropTypes.string
};
export default TextValueWithEllipsis;
