import React, { useCallback, useState } from 'react';
import StepUpAuthenticationModal from '../components/StepUpAuthentication/Modal';
const useStepUpAuthenticationModal = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const openModal = useCallback(() => setModalIsOpen(true), []);
    const closeModal = useCallback(() => setModalIsOpen(false), []);
    const Modal = () => React.createElement(StepUpAuthenticationModal, { isOpen: modalIsOpen, onClose: closeModal });
    return { Modal, openModal };
};
export default useStepUpAuthenticationModal;
