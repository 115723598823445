import { Separator, SpacerVertical } from '@klarna/bubble-ui';
import { Layout, useResponsiveContext } from '@klarna/mp-ui';
import React from 'react';
import styled from 'styled-components';
import { ApiKeySection } from './ApiKeySection';
import ClientIdSection from './ClientIdSection/ClientIdSection';
import StepConfigureBoostFeatures from './StepConfigureBoostFeatures';
import { StepInstallPlugin } from './StepInstallPlugin';
import StepStartSetup from './StepStartSetup';
const Box = styled.div.withConfig({ displayName: "Box", componentId: "sc-10w9ym3" }) `
  text-align: center;
`;
const StepsContainer = styled.div.withConfig({ displayName: "StepsContainer", componentId: "sc-ze6o81" }) `
  margin-right: 24px;
`;
export default function PluginTabContent() {
    const { isMobile, isTablet } = useResponsiveContext();
    return (React.createElement(Layout.Section, null,
        React.createElement(Layout.Column, { mobileWidth: 12, tabletWidth: 12, desktopWidth: 8, tabletOrder: 2, mobileOrder: 2, desktopOrder: 1 },
            React.createElement(Box, null,
                React.createElement(StepsContainer, null,
                    React.createElement(StepInstallPlugin, null),
                    React.createElement(SpacerVertical, { spaceToken: 'space/500' }),
                    React.createElement(Separator, { colorToken: 'colors/borders/subtle-floating' }),
                    React.createElement(SpacerVertical, { spaceToken: 'space/500' }),
                    React.createElement(StepStartSetup, null),
                    React.createElement(SpacerVertical, { spaceToken: 'space/500' }),
                    React.createElement(Separator, { colorToken: 'colors/borders/subtle-floating' }),
                    React.createElement(SpacerVertical, { spaceToken: 'space/500' }),
                    React.createElement(StepConfigureBoostFeatures, null),
                    React.createElement(SpacerVertical, { spaceToken: 'space/500' }),
                    React.createElement(Separator, { colorToken: 'colors/borders/subtle-floating' })))),
        React.createElement(Layout.Column, { mobileWidth: 12, tabletWidth: 12, desktopWidth: 4, tabletOrder: 1, mobileOrder: 1, desktopOrder: 2 },
            React.createElement(Box, null,
                React.createElement(Box, null,
                    React.createElement(ClientIdSection, null)),
                React.createElement(SpacerVertical, { spaceToken: "space/300" }),
                React.createElement(Box, null,
                    React.createElement(ApiKeySection, null))),
            (isMobile || isTablet) && React.createElement(SpacerVertical, { spaceToken: 'space/500' }))));
}
