import { StoreSelector } from 'mage-components';
import React from 'react';
import styled from 'styled-components';
import { useMid } from '../hooks/useMid';
const MainContainer = styled.div.withConfig({ displayName: "MainContainer", componentId: "sc-40l712" }) `
  max-width: ${props => props.maxWidth || '250px'};
`;
export default function MidSelector({ maxWidth }) {
    const { userMids, selectedMid, handleMidChange } = useMid();
    return (React.createElement(MainContainer, { maxWidth: maxWidth },
        React.createElement(StoreSelector, { borderless: true, mids: userMids, value: selectedMid === null ? undefined : selectedMid, 
            // @ts-ignore: fix type of onChange
            onChange: handleMidChange })));
}
