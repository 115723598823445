import { IllustrationSmooth, SpacerVertical } from '@klarna/bubble-ui';
import { Framework } from '@merchant-portal/framework';
import { format } from 'date-fns-tz';
import { CenteredContent } from 'mage-components';
import { hooks as i18nHooks } from 'mage-i18n';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import EnvironmentIdentifier from '../EnvironmentIdentifier';
const Content = ({ until }) => {
    const t = i18nHooks.useTranslator();
    useEffect(() => {
        const title = t('core.maintenance.pageTitle');
        document.title = title;
    }, [t]);
    let summary;
    if (until) {
        const untilTime = format(new Date(parseInt(until)), 'Pp', {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        });
        summary = t('core.maintenance.scheduledSummary', { until: untilTime });
    }
    else {
        summary = t('core.maintenance.summary');
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(SpacerVertical, { large: true }),
        React.createElement(CenteredContent, { illustration: React.createElement(IllustrationSmooth, null), title: t('core.maintenance.title'), summary: summary })));
};
Content.propTypes = {
    until: PropTypes.string
};
const Maintenance = ({ footerConfig, until }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(EnvironmentIdentifier, null),
        React.createElement(Framework, { footerConfig: footerConfig },
            React.createElement(Content, { until: until }))));
};
Maintenance.propTypes = {
    footerConfig: PropTypes.shape({
        text: PropTypes.string.isRequired,
        links: PropTypes.arrayOf(PropTypes.shape({
            linkText: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired
        }))
    }).isRequired,
    until: PropTypes.string
};
export default Maintenance;
export { Content };
