import { __rest } from "tslib";
import { ButtonPrimary, ButtonQuaternary, ButtonRoundPrimary, ButtonRoundQuaternary } from '@klarna/bubble-ui';
import { useResponsiveContext } from '@klarna/mp-ui';
import PropTypes from 'prop-types';
import React from 'react';
const BUTTON_SIZE = 'medium';
function FullButton(_a) {
    var { id, Icon, onClick, label, toggled } = _a, buttonProps = __rest(_a, ["id", "Icon", "onClick", "label", "toggled"]);
    const ButtonComponent = toggled ? ButtonPrimary : ButtonQuaternary;
    return (React.createElement(ButtonComponent, Object.assign({ id: id, icon: Icon, onClick: onClick, size: BUTTON_SIZE }, buttonProps), label));
}
function IconButton(_a) {
    var { id, Icon, onClick, label, toggled } = _a, buttonProps = __rest(_a, ["id", "Icon", "onClick", "label", "toggled"]);
    const ButtonComponent = toggled ? ButtonRoundPrimary : ButtonRoundQuaternary;
    return (React.createElement(ButtonComponent, Object.assign({ id: id, title: label, onClick: onClick, size: BUTTON_SIZE }, buttonProps),
        React.createElement(Icon, null)));
}
function TopBarActionButton(_a) {
    var { iconOnly = false } = _a, buttonProps = __rest(_a, ["iconOnly"]);
    const { isMobile } = useResponsiveContext();
    const ButtonComponent = isMobile || iconOnly ? IconButton : FullButton;
    return React.createElement(ButtonComponent, Object.assign({}, buttonProps));
}
TopBarActionButton.propTypes = {
    iconOnly: PropTypes.bool,
    id: PropTypes.string,
    Icon: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func]).isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    toggled: PropTypes.bool
};
export default TopBarActionButton;
// Allows storybook to get props
export const TopBarActionButtonPropsDoc = () => null;
