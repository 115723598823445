import { __rest } from "tslib";
import { ActionIconChevronRight } from '@klarna/bubble-ui';
import { useGetToken } from '@klarna/mp-ui';
import React from 'react';
import styled from 'styled-components';
const TaskItemsStyled = styled.ul.withConfig({ displayName: "TaskItemsStyled", componentId: "sc-1m9wfc1" })(() => {
    const paddingHorizontal = useGetToken('space/100').value();
    return {
        listStyle: 'none',
        padding: `0 ${paddingHorizontal}px`,
        margin: 0
    };
});
const TaskItemWrapperStyled = styled.li.withConfig({ displayName: "TaskItemWrapperStyled", componentId: "sc-9ukll5" })(() => {
    const backgroundColor = useGetToken('colors/backgrounds/subtle-inline').toString();
    const marginLeft = useGetToken('space/400').value();
    return {
        ':after': {
            content: '" "',
            height: 1,
            backgroundColor,
            display: 'block',
            marginLeft
        },
        ':last-of-type:after': {
            display: 'none'
        }
    };
});
// @ts-ignore: FIXME
const TaskItemStyled = styled.a.withConfig({ displayName: "TaskItemStyled", componentId: "sc-16stnp9" })(() => {
    const spacing = useGetToken('space/200').value();
    const borderRadius = useGetToken('corner-radius/s').value();
    const _a = useGetToken('text-style/text/super-paragraphs/body/regular').props, { lineHeight } = _a, fontStyles = __rest(_a, ["lineHeight"]);
    const textColor = useGetToken('colors/text/default').toString();
    const backgroundHoverColor = useGetToken('colors/backgrounds/subtle-inline').toString();
    return Object.assign(Object.assign({}, fontStyles), { borderRadius, cursor: 'pointer', lineHeight: `${lineHeight}px`, display: 'flex', padding: spacing, color: textColor, gap: spacing, transition: 'background-color 0.15s ease-out', ':hover': {
            backgroundColor: backgroundHoverColor
        } });
});
const TaskItemLabelStyled = styled.span.withConfig({ displayName: "TaskItemLabelStyled", componentId: "sc-1tcxra2" })(() => {
    return {};
});
const ArrowIconStyled = styled.span.withConfig({ displayName: "ArrowIconStyled", componentId: "sc-1t0bchb" })(() => {
    return {
        marginLeft: 'auto',
        marginRight: 0
    };
});
export function TaskItems({ options, onSelect }) {
    return (React.createElement(TaskItemsStyled, null, options.map(({ value, label, icon }) => {
        return (React.createElement(TaskItemWrapperStyled, { key: label },
            React.createElement(TaskItemStyled, { onClick: () => onSelect(value) },
                icon,
                React.createElement(TaskItemLabelStyled, null, label),
                React.createElement(ArrowIconStyled, null,
                    React.createElement(ActionIconChevronRight, { colorToken: 'colors/borders/neutral' })))));
    })));
}
