import { SpacerVertical, Typography } from '@klarna/bubble-ui';
import { useGetToken } from '@klarna/mp-ui';
import { hooks as coreHooks } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
import React from 'react';
import styled, { css } from 'styled-components';
import LoadingComponent from '../Loader';
import EmptyOrErrorComponent from './EmptyOrErrorComponent';
import TaskDataProvider from './TaskDataProvider';
import TaskListEnhanced from './TaskList';
const RoundedContainer = styled.div.withConfig({ displayName: "RoundedContainer", componentId: "sc-1x2jj98" })(() => {
    const borderColor = useGetToken('colors/borders/subtle-floating').toString();
    const borderRadius = useGetToken('corner-radius/m').value();
    const paddingVertical = useGetToken('space/300').value();
    return css({
        border: `1px solid ${borderColor}`,
        borderRadius,
        padding: `${paddingVertical}px 0`
    });
});
const TitleContainer = styled.div.withConfig({ displayName: "TitleContainer", componentId: "sc-v2y3b3" })(() => {
    const paddingHorizontal = useGetToken('space/300').value();
    return {
        padding: `0 ${paddingHorizontal}px`
    };
});
function TaskListComponent() {
    const t = i18nHooks.useTranslator();
    const tokenHelper = coreHooks.useTokenHelper();
    const isTransactingUser = tokenHelper ? tokenHelper.isTransactingUser() : false;
    const hasOrdersAccess = tokenHelper ? tokenHelper.hasClientForEntityType('merchant', 'orders-fe') : false;
    if (!isTransactingUser) {
        return null;
    }
    return (React.createElement(RoundedContainer, null,
        React.createElement(TitleContainer, null,
            React.createElement(Typography, { textToken: 'text-style/headings/blocks/bold/grande' }, t('home-fe.tasks.title'))),
        React.createElement(SpacerVertical, { spaceToken: 'space/300' }),
        React.createElement(TaskDataProvider, { includeOrders: hasOrdersAccess, onError: () => (React.createElement(EmptyOrErrorComponent, { isError: true, title: t('home-fe.tasks.error.title'), desc: t('home-fe.tasks.error.desc') })), onLoading: () => React.createElement(LoadingComponent, null) }, tasks => React.createElement(TaskListEnhanced, { tasks: tasks }))));
}
export default TaskListComponent;
