import { ColoredIllustrationEmptyState, IconAnnouncement, Link, Loader, SpacerVertical, Typography } from '@klarna/bubble-ui';
import { Flex, useGetToken } from '@klarna/mp-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import Insights from 'mage-insights';
import React from 'react';
import styled, { css } from 'styled-components';
import { CenteredContent, NotificationTitle } from './common';
export const NotificationLoading = () => {
    const t = i18nHooks.useTranslator();
    return (React.createElement(Flex, { style: { width: '100%' } },
        React.createElement(NotificationTitle, null, t('core.notification.title')),
        React.createElement(CenteredContent, { "data-testid": "klarna-loader" },
            React.createElement(Loader, null))));
};
export const NotificationEmpty = () => {
    const t = i18nHooks.useTranslator();
    return (React.createElement(Flex, { style: { width: '100%' } },
        React.createElement(NotificationTitle, null, t('core.notification.title')),
        React.createElement(SpacerVertical, { spaceToken: "space/200" }),
        React.createElement(CenteredContent, { style: { flexDirection: 'column' } },
            React.createElement(ColoredIllustrationEmptyState, { size: 80 }),
            React.createElement(SpacerVertical, { spaceToken: "space/200" }),
            React.createElement(Typography, { textToken: "text-style/text/paragraphs/default/regular" }, t('core.notification.content.uptodate')))));
};
const NotificationContainer = styled.div(() => {
    const backgroundColor = useGetToken('colors/backgrounds/subtle-inline').toString();
    const cornerRadiusS = useGetToken('corner-radius/s').value();
    return css({
        padding: '5px',
        '&:hover': {
            backgroundColor,
            borderRadius: `${cornerRadiusS}px`
        }
    });
});
export const NotificationList = ({ notifications }) => {
    const t = i18nHooks.useTranslator();
    const trackNotificationClick = category => {
        Insights.event({
            category,
            action: 'click'
        });
    };
    return (React.createElement(Flex, null,
        React.createElement(NotificationTitle, null, t('core.notification.title')),
        React.createElement(SpacerVertical, { spaceToken: "space/200" }),
        notifications.map(notification => (React.createElement(NotificationContainer, { key: notification.title },
            React.createElement(Link, { onPress: () => {
                    trackNotificationClick(notification.analytics.category);
                }, href: notification.link.href },
                React.createElement(Flex, { style: { gap: '16px', padding: '5px' } },
                    React.createElement(Flex, null,
                        React.createElement(IconAnnouncement, null)),
                    React.createElement(Flex, { style: { flex: 1 } },
                        React.createElement(Typography, { textToken: "text-style/text/paragraphs/default/bold" }, notification.title),
                        React.createElement(SpacerVertical, { spaceToken: "space/100" }),
                        React.createElement(Typography, { textToken: "text-style/text/paragraphs/default/regular" }, notification.content),
                        React.createElement(SpacerVertical, { spaceToken: "space/100" }),
                        React.createElement(Link, { isUnderlined: true, textToken: "text-style/text/paragraphs/default/regular" }, notification.link.content)))))))));
};
