import { ButtonPrimary, ButtonRoundPrimary, IconStores } from '@klarna/bubble-ui';
import { useGetToken, useResponsiveContext, useResponsiveTokenValue, useWidth } from '@klarna/mp-ui';
import { hooks as coreHooks, selectors as coreSelectors } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
import mageInsights from 'mage-insights';
import { thunks as stateThunks } from 'mage-state';
import * as R from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import TextValueWithEllipsis from './TextValueWithEllipsis';
const MAX_MIDS_TO_DISPLAY = 5;
const Container = styled.div(() => {
    const backgroundColor = useGetToken('colors/backgrounds/warning-inline').toString();
    const horizontalPadding = useGetToken('space/300').value();
    const horizontalMargin = useResponsiveTokenValue('framework/main/margin');
    return css({
        display: 'flex',
        alignItems: 'center',
        position: 'sticky',
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
        height: 75,
        maxHeight: 80,
        marginLeft: -horizontalMargin,
        marginRight: -horizontalMargin,
        padding: `0 ${horizontalPadding}px`,
        backgroundColor
    });
});
const ButtonWrapper = styled.div({
    minWidth: 'fit-content'
});
function KuasPrompter() {
    const tokenHelper = coreHooks.useTokenHelper();
    const mids = tokenHelper === null || tokenHelper === void 0 ? void 0 : tokenHelper.getMids();
    const getMerchantDataByMids = useSelector(coreSelectors.getMerchantsByMids);
    const merchantData = getMerchantDataByMids(mids !== null && mids !== void 0 ? mids : []);
    const clientId = useSelector(coreSelectors.clientId);
    const isKlarnaRealm = useSelector(coreSelectors.isKlarnaRealm);
    const isRequesterPartner = !!useSelector(coreSelectors.isRequesterPartner);
    const shouldRender = isKlarnaRealm || clientId === 'partner-portal' || isRequesterPartner;
    if (!shouldRender || !tokenHelper) {
        return null;
    }
    return (React.createElement(Container, { "data-testid": "kuasPrompter" },
        React.createElement(MidsList, { merchantData: merchantData }),
        React.createElement(ButtonWrapper, null,
            React.createElement(ChooseButton, { merchantsCount: merchantData.length, isRequesterPartner: isRequesterPartner }))));
}
const MidListWrapper = styled.div(() => {
    const gap = useGetToken('space/300').value();
    return css({
        display: 'flex',
        flexGrow: 1,
        gap
    });
});
function MidsList({ merchantData }) {
    const t = i18nHooks.useTranslator();
    const [ref, width] = useWidth();
    const showMids = merchantData.length <= MAX_MIDS_TO_DISPLAY;
    if (!showMids) {
        return (
        // @ts-expect-error: FIXME - not sure why it's broken
        React.createElement(MidListWrapper, { ref: ref },
            React.createElement(TextValueWithEllipsis, { title: t('core.kuasPrompter.title'), content: t('core.kuasPrompter.content', { count: merchantData.length }) })));
    }
    return (
    // @ts-expect-error: FIXME - not sure why it's broken
    React.createElement(MidListWrapper, { ref: ref }, merchantData.map(({ merchantId, storeName, partners }) => (React.createElement("div", { key: merchantId, "data-testid": "midItem", style: { flexGrow: 1 } },
        React.createElement(TextValueWithEllipsis, { title: storeName || '-', content: merchantId, overflowEllipsisWidth: width && width / merchantData.length, subtitle: partners.map(R.prop('name')).join(' | ') }))))));
}
function ChooseButton({ isRequesterPartner, merchantsCount }) {
    const t = i18nHooks.useTranslator();
    const { isDesktop } = useResponsiveContext();
    const tokenHelper = coreHooks.useTokenHelper();
    const username = tokenHelper ? tokenHelper.getUsername() : undefined;
    const dispatch = useDispatch();
    const login = options => dispatch(stateThunks.login(options));
    const handleClick = () => {
        mageInsights.event({
            category: 'KUASPrompter',
            action: 'change-selected-mids',
            label: `${merchantsCount} MIDs`
        });
        const redirectUrl = new URL(window.location.href);
        if (isRequesterPartner && !redirectUrl.searchParams.has('partner', 'true')) {
            redirectUrl.searchParams.append('partner', 'true');
        }
        return login({
            prompt: 'login',
            loginHint: username,
            redirectUri: redirectUrl.toString()
        });
    };
    if (!isDesktop) {
        return (React.createElement(ButtonRoundPrimary, { onPress: handleClick },
            React.createElement(IconStores, null)));
    }
    return (React.createElement(ButtonPrimary, { size: "medium", onClick: handleClick }, t('core.kuasPrompter.button')));
}
export default KuasPrompter;
