import { __awaiter } from "tslib";
import { useFeature } from '@merchant-portal/experimentation';
import { hooks as coreHooks, selectors as coreSelectors } from 'mage-core';
import { thunks as stateThunks } from 'mage-state';
import { useDispatch, useSelector } from 'react-redux';
const useAuthStatus = () => {
    const dispatch = useDispatch();
    const emberClient = useSelector(coreSelectors.createBackendClient)('users');
    const defaultAuthLevel = 'krn:portal-auth:loa:2fa';
    const isStepUpAuthenticationForAppsEnabled = useFeature('merchant-portal.deep-link-2fa-flow.enabled');
    const tokenHelper = coreHooks.useTokenHelper();
    const authLevel = (tokenHelper === null || tokenHelper === void 0 ? void 0 : tokenHelper.getAuthLevel()) || defaultAuthLevel;
    const setUpAuthFactors = (tokenHelper === null || tokenHelper === void 0 ? void 0 : tokenHelper.getSetupAuthFactors()) || [];
    const hasUserAuthLevel = (expectedAuthLevel) => isStepUpAuthenticationForAppsEnabled ? authLevel === expectedAuthLevel : true;
    const completeAuthentication = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (acr = {
        values: [defaultAuthLevel],
        essential: true
    }) {
        const shouldRequirePassword = !(tokenHelper === null || tokenHelper === void 0 ? void 0 : tokenHelper.hasSetupPassword()) && authLevel === 'krn:portal-auth:loa:0fa';
        const shouldRequire2fa = !(tokenHelper === null || tokenHelper === void 0 ? void 0 : tokenHelper.hasSetup2fa());
        const userId = tokenHelper === null || tokenHelper === void 0 ? void 0 : tokenHelper.getUserId();
        if (userId && authLevel !== 'krn:portal-auth:loa:2fa' && (shouldRequirePassword || shouldRequire2fa)) { // if user has 2fa level already, this won't touch their required actions and just re-trigger the login
            const query = new URLSearchParams(Object.assign(Object.assign({}, shouldRequirePassword ? { password: 'true' } : {}), shouldRequire2fa ? { '2fa': 'true' } : {}));
            yield emberClient.put(`/users/${userId}/auth-setup?${query.toString()}`);
        }
        return dispatch(stateThunks.login({ acr }));
    });
    return {
        authLevel,
        completeAuthentication,
        hasUserAuthLevel,
        setUpAuthFactors
    };
};
export default useAuthStatus;
