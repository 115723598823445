import { __rest } from "tslib";
import { Typography } from '@klarna/bubble-ui';
import React from 'react';
import styled from 'styled-components';
import { GradientBadge } from '../../common/GradientBadge';
const BaseButton = styled.button `
  padding: 0px;
  margin: 0px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;
const ButtonInnerContainer = styled.div `
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-shrink: 0;
  border-style: solid;
  border-width: 0px;
  position: relative;
  z-index: 0;
  min-height: 0px;
  min-width: 0px;
  background-color: ${props => (props.active ? 'rgb(14, 14, 15)' : 'none')};
  border-radius: 999px;
  height: 40px;
  justify-content: center;
  padding: 10px 16px;
  transition:
    background-color 0.2s ease 0s,
    border-color 0.2s ease 0s,
    color 0.2s ease 0s;

  &:hover {
    background-color: ${props => (props.active ? 'rgb(51, 53, 54)' : 'rgba(2, 14, 15, 0.04)')};
  }

  &:active {
    background-color: ${props => (props.active ? 'rgb(13, 14, 15)' : 'rgba(2, 14, 15, 0.06)')};
  }
`;
const IconContainer = styled.div `
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex-shrink: 0;
  border-style: solid;
  border-width: 0px;
  position: relative;
  z-index: 0;
  min-height: 0px;
  min-width: 0px;
  padding-right: ${props => (props.content.length > 0 ? '10px' : '0')};
`;
const BadgeContainer = styled.div `
  padding-left: 10px;
  padding-top: 2px;
`;
export const TabButton = (_a) => {
    var { content = '', active = false, icon: Icon, badgeContent = '' } = _a, btnProps = __rest(_a, ["content", "active", "icon", "badgeContent"]);
    return (React.createElement(BaseButton, Object.assign({}, btnProps),
        React.createElement(ButtonInnerContainer, { active: active },
            Icon && (React.createElement(IconContainer, { content: content },
                React.createElement(Icon, null))),
            React.createElement(Typography, { colorToken: active ? 'colors/text/inverse' : 'colors/text/default', textToken: "text-style/text/paragraphs/default/bold" }, content),
            badgeContent.length > 0 && (React.createElement(BadgeContainer, null,
                React.createElement(GradientBadge, { content: badgeContent }))))));
};
