import React, { useContext, useState } from 'react';
const SidePanelContext = React.createContext({});
function useSidePanelContext() {
    return useContext(SidePanelContext);
}
function SidePanelContextProvider({ children }) {
    const [isSidePanelOpen, setSidePanelOpen] = useState(false);
    const [hasTabs, setHasTabs] = useState(false);
    return (React.createElement(SidePanelContext.Provider, { value: { hasTabs, setHasTabs, isSidePanelOpen, setSidePanelOpen } }, children));
}
export { SidePanelContext, SidePanelContextProvider, useSidePanelContext };
// Allows storybook to get props
export const SidePanelContextPropsDoc = () => null;
