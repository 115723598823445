import { __awaiter } from "tslib";
import actions from './actions';
import * as selectors from './selectors';
const fetchDistributionPartner = (tokenHelper) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const loginSource = tokenHelper.getLoginSource();
    const merchantPortalClient = selectors.createMerchantPortalApiClient(state);
    if (!loginSource) {
        // @ts-ignore: FIXME
        return dispatch(actions.distributionPartner.set(null));
    }
    try {
        const distributionPartner = yield merchantPortalClient.get(`/partners/${loginSource}`);
        // @ts-ignore: FIXME
        dispatch(actions.distributionPartner.set(distributionPartner.data));
    }
    catch (error) {
        // @ts-ignore: FIXME
        dispatch(actions.distributionPartner.set(null));
    }
});
const fetchPartners = (tokenHelper) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const partnerAccountIds = tokenHelper.getPartnerAccounts();
    const merchantPortalClient = selectors.createMerchantPortalApiClient(state);
    const requests = partnerAccountIds.map(partnerAccountId => {
        return merchantPortalClient.get(`/partners/${partnerAccountId}`);
    });
    const responses = yield Promise.allSettled(requests);
    const partnerAccounts = responses.filter(promise => promise.status === 'fulfilled')
        .map(fulfilledPromise => fulfilledPromise.value.data);
    // @ts-ignore: FIXME
    dispatch(actions.partnerAccounts.set(partnerAccounts));
});
export { fetchDistributionPartner, fetchPartners };
