import { useResponsiveContext } from '@klarna/mp-ui';
import { selectors as coreSelectors } from 'mage-core';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useTablet } from '../../../hooks/useMediaQuery';
import { useSidebarContext } from '../Sidebar';
import PopoverBoostersInfo from './PopOverBoostersInfo';
import { TabButton } from './TabButton';
import useActiveTab from './useActiveTab';
import useShouldShowTabs from './useShouldShowTabs';
const TabRowContainer = styled.div `
  grid-area: tabs;
  display: flex;
  padding-left: 0px;

  ${props => props.tabletMediaQuery} {
    padding-left: 12px;
  }
`;
const TabsContainer = styled.div `
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  background-color: ${props => (props.isHighlighted ? 'white' : 'none')};
  width: 100%;
  padding-left: 16px;
  ${props => props.tabletMediaQuery} {
    border-radius: ${props => (props.isHighlighted ? '8px' : '0px')};
    width: auto;
    padding-right: 8px;
    padding-left: 4px;
  }
`;
const TabItem = styled.div({
    display: 'flex',
    padding: 8
});
function Tabs({ groupedSidebarItems, items, sidebarItems, defaultTab }) {
    const { setSidebarConfig } = useSidebarContext();
    const { isMobile } = useResponsiveContext();
    const tabsRef = useRef();
    const boostersTabRef = useRef();
    const { shouldShowTabs } = useShouldShowTabs(items);
    const { activeTab, setLastActiveTab } = useActiveTab({
        tabs: items,
        sidebarItems,
        defaultTab
    });
    const isHomeApp = useSelector(coreSelectors.isHomeApp);
    const history = useHistory();
    useEffect(() => {
        const displayedTab = groupedSidebarItems[activeTab]
            ? activeTab
            : Object.keys(groupedSidebarItems)[0];
        setSidebarConfig({ items: groupedSidebarItems[displayedTab] });
        setLastActiveTab(displayedTab);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);
    const tabletMediaQuery = useTablet();
    const handleOnClick = (tabId, onClick) => () => {
        const tab = items.find(tab => tab.id === tabId);
        onClick === null || onClick === void 0 ? void 0 : onClick(tabId);
        if (isHomeApp) {
            history.push(tab.homeApp.path);
        }
        else {
            window.location.assign(tab.homeApp.path);
        }
    };
    const visibleTabs = items.filter(tab => tab.id in groupedSidebarItems);
    if (groupedSidebarItems[activeTab] === undefined) {
        return isHomeApp ? React.createElement(Redirect, { to: visibleTabs[0].homeApp.path }) : null;
    }
    else if (visibleTabs.length > 1) {
        return (React.createElement(React.Fragment, null,
            React.createElement(PopoverBoostersInfo, { boostersTabRef: boostersTabRef }),
            shouldShowTabs && (React.createElement(TabRowContainer, { tabletMediaQuery: tabletMediaQuery },
                React.createElement(TabsContainer, { tabletMediaQuery: tabletMediaQuery, ref: tabsRef }, visibleTabs.map(item => {
                    const isActiveTab = activeTab === item.id;
                    const isInactiveTabAndMobile = !isActiveTab && isMobile;
                    return (React.createElement(TabItem, { key: item.id, role: "tab", "aria-selected": activeTab === item.id, ref: item.id === 'boosters' ? boostersTabRef : undefined },
                        React.createElement(TabButton, { "aria-label": item.label, active: isActiveTab, key: item.id, content: isInactiveTabAndMobile ? '' : item.label, onClick: handleOnClick(item.id, item.onClick), badgeContent: item.badgeContent, icon: item.icon })));
                }))))));
    }
    else {
        return null;
    }
}
export default Tabs;
