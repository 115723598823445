import { IconClock, IconEnvelope, SpacerVertical, Typography } from '@klarna/bubble-ui';
import { selectors as coreSelectors } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
const ContentContainer = styled.div `
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const Feature = styled.div `
  display: flex;
  align-items: center;
  gap: 8px;
`;
const SetUpRequired = () => {
    const t = i18nHooks.useTranslator();
    const partnerData = useSelector(coreSelectors.getDistributionPartner);
    const distributionPartnerName = partnerData === null || partnerData === void 0 ? void 0 : partnerData.name;
    return (React.createElement(ContentContainer, null,
        React.createElement("div", null,
            React.createElement(Typography, { textToken: 'text-style/text/paragraphs/grande/regular' }, t('core.stepUpAuthentication.setUp.description'))),
        React.createElement(SpacerVertical, { spaceToken: "space/300" }),
        React.createElement(Feature, null,
            React.createElement(IconClock, null),
            React.createElement(Typography, { textToken: 'text-style/text/paragraphs/tall/regular' }, t('core.stepUpAuthentication.setUp.features.speed'))),
        distributionPartnerName && (React.createElement(React.Fragment, null,
            React.createElement(SpacerVertical, { spaceToken: "space/200" }),
            React.createElement(Feature, null,
                React.createElement(IconEnvelope, null),
                React.createElement(Typography, { textToken: 'text-style/text/paragraphs/tall/regular' }, t('core.stepUpAuthentication.setUp.features.email', { distributionPartner: distributionPartnerName })))))));
};
export default SetUpRequired;
