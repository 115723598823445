import { ResponsiveContextProvider, useGetToken, useThemeBreakpoints } from '@klarna/mp-ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useDesktop, useTablet } from '../../hooks/useMediaQuery';
import AppContent from './AppContent';
import { SIDEBAR_DEFAULT_WIDTH, SIDEBAR_TABLET_WIDTH, SIDEPANEL_WIDTH, zIndex } from './constants';
import { NotificationsContextProvider } from './Contexts/NotificationsContext';
import { SidePanelContextProvider, useSidePanelContext } from './Contexts/SidePanelContext';
import { StateContext } from './Contexts/StateContext';
import Footer from './Footer';
import Sidebar, { SidebarProvider } from './Sidebar';
import supportedIcons from './Sidebar/icons';
import Tabs from './Tabs';
import TopBar from './TopBar';
const ContainerStyled = styled.div(({ hideTopBar, hideSidebar, hasTabs }) => {
    const { isSidePanelOpen, setHasTabs } = useSidePanelContext();
    setHasTabs(hasTabs);
    const tabletMediaQuery = useTablet();
    const desktopMediaQuery = useDesktop();
    const backgroundColor = useGetToken('colors/backgrounds/subtle-inline').toString();
    const topbarHeight = useGetToken('space/800').value();
    const getTemplateRows = () => {
        if (hideTopBar) {
            return '0px 1fr';
        }
        if (hasTabs) {
            return `${topbarHeight}px ${topbarHeight}px 1fr`;
        }
        return `${topbarHeight}px 1fr`;
    };
    return css({
        height: '100%',
        width: '100%',
        backgroundColor,
        position: 'fixed',
        display: 'grid',
        gridTemplateColumns: '0 1fr',
        gridTemplateRows: getTemplateRows(),
        zIndex: zIndex.root,
        gridTemplateAreas: hasTabs
            ? `
        "topbar topbar panel"
        "tabs tabs panel"
        "sidebar app panel"
        `
            : `
        "topbar topbar panel"
        "sidebar app panel"
        `,
        [tabletMediaQuery]: {
            gridTemplateColumns: `${hideSidebar ? 0 : SIDEBAR_TABLET_WIDTH}px 1fr ${isSidePanelOpen ? `${SIDEPANEL_WIDTH}px` : 0}`
        },
        [desktopMediaQuery]: {
            gridTemplateColumns: `${hideSidebar ? 0 : SIDEBAR_DEFAULT_WIDTH}px 1fr ${isSidePanelOpen ? `${SIDEPANEL_WIDTH}px` : 0}`
        }
    });
});
const ScrollableStyled = styled.div({
    boxSizing: 'border-box',
    position: 'relative',
    overflowY: 'auto',
    scrollBehavior: 'smooth',
    gridArea: 'app',
    zIndex: zIndex.mainContent
});
const SidePanelStyled = styled.div(() => {
    const { isSidePanelOpen } = useSidePanelContext();
    const desktopMediaQuery = useDesktop();
    return css({
        gridArea: 'app/app/panel/panel',
        zIndex: zIndex.sidePanel,
        display: isSidePanelOpen ? 'block' : 'none',
        [desktopMediaQuery]: {
            gridArea: 'panel'
        }
    });
});
const initialContext = {
    state: {
        hideFooter: false,
        hideTopBar: false,
        hideSidebar: false
    }
};
/** A navigation framework that provides a common template, context and responsive utilities. */
function MarbleFramework({ children, error, id, isLoading, isFullScreenContent, sidePanel, footerConfig, sidebarConfig, topBarConfig }) {
    var _a;
    const breakpoints = useThemeBreakpoints();
    const [stateContext, setStateContext] = useState(initialContext.state);
    const isSidebarHidden = () => {
        return !sidebarConfig || stateContext.hideSidebar;
    };
    const isTopBarHidden = () => {
        return !topBarConfig || stateContext.hideTopBar;
    };
    const isFooterHidden = () => {
        return !footerConfig || stateContext.hideFooter;
    };
    const [hideFooter, hideSidebar, hideTopBar] = [
        isFooterHidden(),
        isSidebarHidden(),
        isTopBarHidden()
    ];
    const { items = [], tabs = [] } = sidebarConfig || {};
    const groupedSidebarItems = tabs.reduce((acc, current) => {
        const tabItems = items.filter(({ tabs = [] }) => tabs.includes(current.id));
        if (tabItems.some(({ isBottomItem }) => !isBottomItem) || current.alwaysShow) {
            return Object.assign(Object.assign({}, acc), { [current.id]: [current.homeApp, ...tabItems] });
        }
        else {
            return acc;
        }
    }, {});
    const hasTabs = tabs.filter(tab => tab.id in groupedSidebarItems).length > 1;
    return (React.createElement(ResponsiveContextProvider, { breakpoints: breakpoints },
        React.createElement(StateContext.Provider, { value: { setState: setStateContext } },
            React.createElement(SidePanelContextProvider, null,
                React.createElement(SidebarProvider, { config: sidebarConfig && Object.assign({}, sidebarConfig) },
                    React.createElement(ContainerStyled, { id: id, hideSidebar: hideSidebar, hideTopBar: hideTopBar, hasTabs: hasTabs },
                        !hideTopBar && React.createElement(TopBar, Object.assign({}, topBarConfig, { showLogoOnMobile: hideSidebar })),
                        ((_a = sidebarConfig === null || sidebarConfig === void 0 ? void 0 : sidebarConfig.tabs) === null || _a === void 0 ? void 0 : _a.length) && (React.createElement(Tabs, { groupedSidebarItems: groupedSidebarItems, items: sidebarConfig.tabs, sidebarItems: sidebarConfig === null || sidebarConfig === void 0 ? void 0 : sidebarConfig.items, defaultTab: sidebarConfig.defaultTab })),
                        !hideSidebar && React.createElement(Sidebar, { hideTopBar: hideTopBar }),
                        React.createElement(ScrollableStyled, null,
                            React.createElement(NotificationsContextProvider, null,
                                React.createElement(AppContent, { stateContext: stateContext, isFrameworkLoading: isLoading, frameworkError: error, isFullScreenContent: isFullScreenContent }, children)),
                            !hideFooter && footerConfig && React.createElement(Footer, Object.assign({}, footerConfig))),
                        sidePanel && React.createElement(SidePanelStyled, null, sidePanel)))))));
}
MarbleFramework.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    error: PropTypes.shape({
        id: PropTypes.string,
        actionButton: PropTypes.shape({
            icon: PropTypes.any,
            id: PropTypes.string,
            onClick: PropTypes.func.isRequired,
            label: PropTypes.string.isRequired
        }),
        contactContent: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
        description: PropTypes.string,
        illustration: PropTypes.any,
        label: PropTypes.string,
        reloadButton: PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string.isRequired
        }),
        title: PropTypes.string
    }),
    id: PropTypes.string,
    isLoading: PropTypes.bool,
    isFullScreenContent: PropTypes.bool,
    sidePanel: PropTypes.node,
    footerConfig: PropTypes.shape({
        text: PropTypes.node,
        links: PropTypes.arrayOf(PropTypes.exact({
            linkText: PropTypes.string.isRequired,
            path: PropTypes.string.isRequired
        }))
    }),
    topBarConfig: PropTypes.shape({
        id: PropTypes.string,
        logo: PropTypes.node,
        actionButtons: PropTypes.arrayOf(PropTypes.node),
        distributionPartner: PropTypes.shape({
            logo_url: PropTypes.string,
            name: PropTypes.string,
            redirect_url: PropTypes.string
        })
    }),
    sidebarConfig: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape({
            active: PropTypes.bool,
            badge: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            icon: PropTypes.oneOf(Object.keys(supportedIcons)),
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            isBadgeBranded: PropTypes.bool,
            isBottomItem: PropTypes.bool,
            isExternalLink: PropTypes.bool,
            isSubmenuItem: PropTypes.bool,
            onClick: PropTypes.func,
            path: PropTypes.string,
            subMenuItem: PropTypes.shape({
                items: PropTypes.arrayOf(PropTypes.shape({
                    active: PropTypes.bool,
                    badge: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                    icon: PropTypes.oneOf(Object.keys(supportedIcons)),
                    id: PropTypes.string.isRequired,
                    label: PropTypes.string.isRequired,
                    isSubmenuItem: PropTypes.bool,
                    onClick: PropTypes.func,
                    path: PropTypes.string
                })),
                parentId: PropTypes.string
            })
        })),
        isOpen: PropTypes.bool
    })
};
export default MarbleFramework;
