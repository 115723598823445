import { SelectorOptions } from '@klarna/bubble-ui';
import { Modal } from '@klarna/mp-ui';
import { hooks as coreHooks } from 'mage-core';
import { hooks as i18nHooks, selectors as i18nSelectors } from 'mage-i18n';
import { selectors as stateSelectors, thunks as stateThunks } from 'mage-state';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
const languageToOption = translatorFactory => language => ({
    value: language,
    id: `app-language-option-${language}`,
    // use the factory so we can display the language name in its own language
    label: translatorFactory(language)(`core.languages.${language}`)
});
const SelectLanguage = ({ onClose }) => {
    const dispatch = useDispatch();
    const t = i18nHooks.useTranslator();
    const translatorFactory = useSelector(i18nSelectors.translatorFactory);
    const language = i18nHooks.useLanguage();
    const languages = useSelector(stateSelectors.getLanguages);
    const tokenHelper = coreHooks.useTokenHelper();
    const userId = tokenHelper.getUserId();
    const saveLanguage = (userId, language) => dispatch(stateThunks.saveLanguage(userId, language));
    const languageOptionsSortedByLabel = languages
        .map(languageToOption(translatorFactory))
        .filter(str => str.label && str.label !== '')
        .sort((a, b) => a.label.localeCompare(b.label));
    const onSelect = language => saveLanguage(userId, language);
    return (React.createElement(Modal, { id: "select-language", onClose: onClose, title: t('core.selectLanguage.title'), description: t('core.selectLanguage.paragraph'), buttonPrimary: {
            id: 'select-language-button',
            onClick: onClose,
            text: t('core.selectLanguage.submit')
        } },
        React.createElement(SelectorOptions, { options: languageOptionsSortedByLabel, defaultValue: language, onSelect: onSelect })));
};
SelectLanguage.propTypes = {
    onClose: PropTypes.func.isRequired
};
export default SelectLanguage;
