import { __rest } from "tslib";
import { ActionIconNewTab, ActionIconPadlock, Badge } from '@klarna/bubble-ui';
import { useResponsiveContext } from '@klarna/mp-ui';
import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import supportedIcons from './icons';
import { BadgeStyled, LabelStyled, LinkStyled, MenuItemIconWrapperStyled, MenuItemStyled, RouterLinkStyled, SecondaryIconWrapperStyled, SUBMENU_ITEM_ACTIVE_CLASSNAME } from './MenuItemStyled';
import { isPartOfCurrentPath, isRootLinkActive, useSidebarContext } from './SidebarContext';
// eslint-disable-next-line react/display-name
const MenuIcon = React.memo(({ icon, active, hasActiveSubmenu, isExpanded }) => {
    const colorToken = useCallback(() => {
        if (!isExpanded && active) {
            return 'colors/text/inverse';
        }
        else if (active && hasActiveSubmenu) {
            return 'colors/text/default';
        }
        else if (active) {
            return 'colors/text/inverse';
        }
        else {
            return 'colors/text/neutral';
        }
    }, [active, isExpanded, hasActiveSubmenu]);
    const iconComponent = useCallback(() => {
        if (typeof icon === 'string' && Object.prototype.hasOwnProperty.call(supportedIcons, icon)) {
            const Icon = supportedIcons[icon];
            return (React.createElement(Icon, { colorToken: colorToken(), UNSAFE_svgProps: { 'data-testid': 'mpui-fw-sidebar-menu-item--menu-icon' } }));
        }
        else if (React.isValidElement(icon)) {
            return icon;
        }
        else {
            return null;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supportedIcons, icon, colorToken]);
    const children = iconComponent();
    return children && React.createElement(MenuItemIconWrapperStyled, null, children);
});
const LinkComponent = (_a) => {
    var { active, path, children, id, isSubmenuItem, hasActiveSubmenu } = _a, otherProps = __rest(_a, ["active", "path", "children", "id", "isSubmenuItem", "hasActiveSubmenu"]);
    const isSubMenuActive = useCallback(match => {
        return !!match || active;
    }, [active]);
    if (isSubmenuItem) {
        return (React.createElement(RouterLinkStyled, Object.assign({}, otherProps, { to: path, activeClassName: SUBMENU_ITEM_ACTIVE_CLASSNAME, id: id, exact: true, isActive: isSubMenuActive, "data-testid": `mpui-fw-sidebar-sub-menu-item-link--${id}` }), children));
    }
    return (React.createElement(LinkStyled, Object.assign({}, otherProps, { active: active, href: path, hasActiveSubmenu: hasActiveSubmenu, id: id, "data-testid": `mpui-fw-sidebar-menu-item-link--${id}` }), children));
};
const isSubmenuActive = (pathname, submenu) => {
    return (submenu === null || submenu === void 0 ? void 0 : submenu.items.filter(submenuItems => pathname.startsWith(submenuItems.path)).length) > 0;
};
function MenuItemComponent(props) {
    const { active, badge, children, label, icon, isBadgeBranded, onClick, onEnter, onLeave, path, id, isExternalApp, isExternalLink, isFocused, isExpanded, tabIndex, subMenu, isSubmenuItem, requires2fa } = props, otherProps = __rest(props, ["active", "badge", "children", "label", "icon", "isBadgeBranded", "onClick", "onEnter", "onLeave", "path", "id", "isExternalApp", "isExternalLink", "isFocused", "isExpanded", "tabIndex", "subMenu", "isSubmenuItem", "requires2fa"]);
    const hasSubmenu = (subMenu === null || subMenu === void 0 ? void 0 : subMenu.items.length) > 0;
    const { isMobile, isTablet } = useResponsiveContext();
    const { setSidebarOpen, userIsMissingAuth } = useSidebarContext();
    const location = useLocation();
    const handleEnter = useCallback((event) => {
        onEnter === null || onEnter === void 0 ? void 0 : onEnter(props, event);
    }, [onEnter, props]);
    const handleClick = useCallback((event) => {
        if (hasSubmenu && !isExpanded) {
            event.preventDefault();
        }
        if (isMobile || isTablet) {
            setSidebarOpen(false);
        }
        onClick === null || onClick === void 0 ? void 0 : onClick(event);
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasSubmenu, isExpanded, isMobile, isTablet]);
    const isLocked = requires2fa && userIsMissingAuth;
    const externalItem = isExternalApp || isExternalLink;
    const isActivePartOfCurrentUrl = isSubmenuItem && isPartOfCurrentPath(path);
    const isActive = active !== null && active !== void 0 ? active : (isRootLinkActive(path) || isActivePartOfCurrentUrl);
    const hasActiveSubmenu = isSubmenuActive(location.pathname, subMenu);
    return (React.createElement(MenuItemStyled, { "data-testid": `mpui-fw-sidebar-menu-item--${id}`, role: "none" },
        React.createElement(LinkComponent, Object.assign({ path: path, "aria-expanded": hasSubmenu && isExpanded, "aria-current": isActive ? 'page' : undefined, "aria-label": label, role: "menuitem", rel: externalItem ? 'noreferrer noopener' : undefined, target: externalItem ? '_blank' : undefined, onClick: handleClick, id: id, isSubmenuItem: isSubmenuItem, hasActiveSubmenu: hasActiveSubmenu, tabIndex: tabIndex, active: isActive, onMouseEnter: handleEnter, onFocus: handleEnter, onBlur: onLeave, onMouseLeave: onLeave, onTouchStart: handleEnter, onTouchEnd: onLeave, isFocused: isFocused, isExpanded: isExpanded }, otherProps),
            icon && (React.createElement(MenuIcon, { active: isActive, icon: icon, hasActiveSubmenu: hasActiveSubmenu, isExpanded: isExpanded })),
            React.createElement(LabelStyled, { isExpanded: isExpanded }, label),
            badge !== undefined && (React.createElement(BadgeStyled, { isExpanded: isExpanded, "data-testid": "mpui-fw-sidebar-menu-item--badge" },
                React.createElement(Badge, { preset: isActive ? 'subtle' : undefined, backgroundColorToken: isBadgeBranded && 'colors/backgrounds/success-inline', textColorToken: isBadgeBranded && 'colors/text/default' }, badge))),
            isLocked && (React.createElement(SecondaryIconWrapperStyled, { "data-testid": "mpui-fw-sidebar-menu-item--requires-mfa" },
                React.createElement(ActionIconPadlock, { colorToken: isActive ? 'colors/text/inverse' : 'colors/text/neutral' }))),
            externalItem && isExpanded && (React.createElement(SecondaryIconWrapperStyled, { "data-testid": "mpui-fw-sidebar-menu-item--external-link" },
                React.createElement(ActionIconNewTab, { colorToken: "colors/text/neutral" })))),
        children));
}
const MenuItem = React.memo(MenuItemComponent);
export default MenuItem;
